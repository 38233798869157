import React from "react";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import "../Style/contatti.css";
import Lottie from "lottie-react";
import animationContact from "../asset/Lottie_files/Animation - contact.json";
import { GrInstagram } from "react-icons/gr";
import { BsFacebook } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";
import CyberWinter from "../asset/CyberWinter.png";
import Snowfall from "../Components/SnowFall";
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";

const Contatti = () => {
  const divStyle = {
    backgroundImage:
      'url("https://images.pexels.com/photos/998641/pexels-photo-998641.jpeg?cs=srgb&dl=pexels-francesco-ungaro-998641.jpg&fm=jpg")', // Assicurati che il percorso sia corretto
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    borderRadius: "10px",
  };
  return (
    <>
      <section className="w-100 h-100 section-background">
        <NavigationBar />
        <SfondoAnimatoOnde />
        <Snowfall />
        <Container className="text-black container-contatti fade_in">
          <h2 className="text-center  my-2 fs-1 fst-italic mb-lg-4 mb-sm-2 fw-bold border-lampeggiante col-numberContact ">
            Cyber Winter
          </h2>
          <p className=" text-center fs-5 text-white">
            Contattaci via EMAIL o WHATSAPP per prendere un appuntamento
            telefonico gratuito ed approfittare dell'offerta!!
            <hr />
            Per festeggiare la nostra nuova apertura con voi abbiamo deciso di
            regalarvi fino al 60% di sconto in fattura, APPROFITTANE SUBITO!
          </p>

          <Col lg={6} md={6} sm={12} xs={12} className=" m-auto">
            <img
              className="w-100 img-CyberWinter my-2"
              src={CyberWinter}
              alt=""
            />
          </Col>

          <Row>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="d-flex flex-column justify-content-center align-items-center mb-3 col-numberContact"
            >
              <div className="d-flex align-items-center mb-3">
                <BsPhone className="fs-2 me-2" />{" "}
                <span className="fs-4"> +39 3293457714</span>
              </div>
              <div className="d-flex align-items-center mb-3">
                <BsWhatsapp className="fs-2 me-2" />{" "}
                <span className="fs-4"> +39 3293457714</span>
              </div>
              <div className="d-flex align-items-center mb-3">
                <AiOutlineMail className="fs-2 me-2" />{" "}
                <span className="fs-4"> allcodingweb@gmail.com</span>
              </div>
              <section className="text-center d-flex justify-content-center align-items-center gap-5 my-5">
                <div className=" d-flex justify-content-center align-items-center gap-3">
                  <Link to={"https://www.instagram.com/all_coding__/"}>
                    <GrInstagram className="fs-1 style-socialIcon" />
                  </Link>

                  <BsFacebook className="fs-1 style-socialIcon" />
                  <AiFillLinkedin className="fs-1 style-socialIcon" />
                </div>
              </section>
              <span>P.Iva 12894820013</span>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Lottie
                className="animationContact-style"
                animationData={animationContact}
                loop={true}
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </section>
    </>
  );
};

export default Contatti;
