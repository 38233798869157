import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RefactoringImg from "../asset/background-refactoring.png";
import NavigationBar from "../Components/NavigationBar";
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";
import Footer from "../Components/Footer";
import RefactoringImg1 from "../asset/refactoring img 1.png";
import RefactoringImg2 from "../asset/refactoring img 2.png";
import RefactoringImg3 from "../asset/refactoring img 3.png";
import "../Style/pageRefactoring.css";

const PageManutenzione = () => {
  return (
    <>
      <NavigationBar />
      <SfondoAnimatoOnde />
      <Container fluid>
        <h1 className="text-center my-4 fs-1 fw-bold fst-italic">
          Refactoring e manutenzione
        </h1>

        <img className="w-100" src={RefactoringImg} alt="img developer" />
      </Container>
      <Container className="text-center my-5">
        <h2 className="fw-bold">Migliora con noi il tuo sito web</h2>
        <span className="fs-5">
          Il nostro team ti aiuterà nel rendere il tuo sito web piu modermo,
          funzionale ed interattivo.{" "}
        </span>
      </Container>
      <Container>
        <Row className="d-flex align-items-start justify-content-center">
          <Col
            className="d-flex flex-column align-items-center justify-content-between mb-5"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <img
              className="w-100 p-3"
              src={RefactoringImg1}
              alt="img 1 refactoring"
            />
            <span className="text-center fw-bold p-3 line-height2 ">
              Il nostro team di esperti della All Coding cercherà di rendere il
              tuo sito web come lo desideri ed aggiungerà funzioni utili per
              farti contattare dai tuoi clienti in modo più smart. Aggiungeremo
              animazioni e potenzieremo la tua SEO per farti salire in cima ai
              motori di ricerca. <hr />
              Inoltre avrai a disposizione il supporto della nostra graphic
              designer per rendere la grafica del tuo sito moderna e
              accattivante.
            </span>
          </Col>
          <Col
            className="d-flex flex-column align-items-center justify-content-center mb-5 "
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <img
              className="w-100 p-3"
              src={RefactoringImg2}
              alt="img 2 refactoring"
            />
            <span className="text-center fw-bold p-3 line-height2">
              Potrai renderlo responsive per la navigazione da qualsiasi tipo di
              schermo, essendo che al giorno d'oggi il 70% delle interazioni con
              un sito web e da dispositivi mobile.
              <hr />
              Potrai trasformarlo in una web app con login, aggiungere form e
              moduli di contatto, collegarci un e-commerce integrato
              completamente personalizzato per la tua azienda e molto altro
              ancora..
            </span>
          </Col>
          <Col
            className="d-flex flex-column align-items-center justify-content-between mb-5 "
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <img
              className="w-100 p-3"
              src={RefactoringImg3}
              alt="img 3 refactoring"
            />
            <span className="text-center fw-bold p-3 line-height2">
              Il nostro obbiettivo è migliorare il tuo sito web per attirare più
              clientela rendendolo molto funzionale e con una grafica moderna e
              personalizzata in base alle vostre richieste. <hr />
              Sarà un sito web veloce, ottimizzato per qualsiasi schermo e con
              servizi di contatto molto rapidi ed efficenti per farti contattare
              facilmente da nuovi potenziali clienti.
            </span>
          </Col>
        </Row>
      </Container>
      ;
      <Footer />
    </>
  );
};

export default PageManutenzione;
