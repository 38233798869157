import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import GraphicImg from "../asset/background-Graphic.png";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";
import "../Style/graphicPage.css";

import Slider from "react-infinite-logo-slider";
import logoIllustrator from "../asset/adobe-illustrator-logo.png";
import logoPhotoshop from "../asset/Adobe_Photoshop_CC_icon.svg.png";
import logoRinoceros from "../asset/Rhinoceros-logo.png";
import logoBlender from "../asset/blender-logo.png";
import logoIndesing from "../asset/adobe-indesign-logo-free-vector-removebg-preview.png";
import { Helmet } from "react-helmet";

import BigliettoRocco from "../asset/Biglietto-rocco.png";
import BigliettoIlaria from "../asset/Biglietto-ilaria.png";
import BigliettoPietro from "../asset/Biglietto-pietro.png";
import BigliettoBrayan from "../asset/Biglietto-brayan.png";

const GraphicDesignerPage = () => {
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Graphic designer</title>
        <meta
          name="description"
          content="La All Coding ti aiutera con un servizio di graphic designer personalizzato per la tua azienda"
        />
      </Helmet>
      <SfondoAnimatoOnde />
      <Container fluid>
        <h1 className="text-center my-4">Graphic Designer </h1>
        <img src={GraphicImg} className="w-100" alt="img graphic designer" />
        <Container>
          <p className="fs-5 text-center my-5 p-4">
            La nostra missione è trasformare idee in design straordinari e
            coinvolgenti e il nostro graphic designer è il partner perfetto per
            portare in vita la vostra visione. Offriamo una vasta gamma di
            servizi di design grafico su misura, che includono la progettazione
            di loghi unici, biglietti da visita eleganti, brochure accattivanti,
            manifesti creativi e tanto altro. <hr />{" "}
            <span className="fw-bold">
              Siamo qui per aiutare le aziende a distinguersi nel mercato
              tramite l'opportunità di creare qualcosa di speciale!
            </span>
          </p>
        </Container>
      </Container>
      <Container className="styleContainer my-5 ">
        <Slider width="200px" duration={30} pauseOnHover={true}>
          <Slider.Slide>
            <div className="text-center stack_icon_hover px-5 mx-5">
              <img
                src={logoIllustrator}
                alt="any"
                className="w-36 stack_icon_img"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img
                src={logoPhotoshop}
                alt="any"
                className="w-36 stack_icon_img"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img
                src={logoIndesing}
                alt="any"
                className="w-36 stack_icon_img"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img
                src={logoRinoceros}
                alt="any"
                className="w-36 stack_icon_img"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img
                src={logoBlender}
                alt="any"
                className="w-36 stack_icon_img"
              />
            </div>
          </Slider.Slide>
        </Slider>
      </Container>
      <Container>
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <span className="mb-3 fw-bold fs-5">Loghi aziendali</span>
          <span className="mb-3 fw-bold fs-5">
            Biglietti da visita professionali{" "}
          </span>
          <span className="mb-3 fw-bold fs-5">Brochure</span>
          <span className="mb-3 fw-bold fs-5">
            Manifesti per eventi o promozioni pubblicitarie
          </span>
          <span className="mb-3 fw-bold fs-5">
            Materiale Promozionale ( volantini,depliant, cataloghi promozionali)
          </span>
          <span className="mb-3 fw-bold fs-5">Etichette personalizzate</span>
          <span className="mb-3 fw-bold fs-5">
            Layout per riviste, giornali e libri
          </span>
        </div>
      </Container>

      <Container>
        <h3 className="text-center my-5 fw-bold fst-italic fs-1">
          Alcuni dei nostri lavori
        </h3>
        <Row>
          <Col lg={3} md={6} sm={12} xs={12}>
            <img src={BigliettoIlaria} alt="" className="w-100 mb-4 shadow" />
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <img src={BigliettoBrayan} alt="" className="w-100 mb-4 shadow" />
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <img src={BigliettoPietro} alt="" className="w-100 mb-4 shadow" />
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <img src={BigliettoRocco} alt="" className="w-100 mb-4 shadow" />
          </Col>
        </Row>
        <div></div>
      </Container>
      <Footer />
    </>
  );
};

export default GraphicDesignerPage;
